import { render, staticRenderFns } from "./listaControlePontos.vue?vue&type=template&id=d2549d08&scoped=true&"
import script from "./listaControlePontos.vue?vue&type=script&lang=js&"
export * from "./listaControlePontos.vue?vue&type=script&lang=js&"
import style0 from "./listaControlePontos.vue?vue&type=style&index=0&id=d2549d08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2549d08",
  null
  
)

export default component.exports